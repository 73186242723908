.showAlertLogText {
  font-size: 15px;
  font-weight: 450;
  padding: 5%;
  color: #4a90e2;
}

.a10-gui-widgets-panel-body {
  padding: 0px;
}

.custom-padding {
  padding: 7px;
}
.m-l-10 {
  margin-left: 10px;
}

.ant-radio-wrapper .ant-radio + span:after {
  border-bottom: 0px !important;
}

.ant-tree-node-content-wrapper span {
  color: #000 !important;
}

.alertDefination-table {
  .ant-table-thead
    > tr
    > th
    .ant-table-header-column
    .ant-table-column-sorters {
    align-items: center;
  }
}

// .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
//   background-color: #4a90e2;
//   .ant-tree-title {
//     color: #fff !important;
//   }
// }
