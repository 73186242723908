.VMWareTemplate {
  width: 222px;
  height: 19px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373737;
  margin-bottom: 16px;
}

.title-container .a10-widget-appicon {
  position: absolute;
  right: -20px;
  top: -8px;
  margin-left: 4px;
  font-size: 14px;
}
