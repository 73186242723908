.userlist {
  table {
    th {
      &:nth-child(2) {
        width: 2%;
      }
    }
  }
}

.user-list {
  .tenantNameCls {
    margin-right: 5px;
  }

  .tenantNameMoreChar {
    margin-right: 5px;
    display: inline-block;
    min-width: 100px;
    text-overflow: ellipsis;
    max-width: 70px;
    overflow: hidden;
  }

  .moreTenantIcon {
    margin-left: 5px;
    border: 1px solid;
    padding: 0px 1px;
    font-size: 10px !important;
  }

  .userRoleIcon {
    padding: 3px;
    color: #6f6f6f;
    font-weight: 500;
    font-size: 12px !important;
    border-radius: 2px;
    background-color: #dfdfdf;
    margin-right: 8px;
  }

  a:hover.tenantName {
    text-decoration: none
  }
}

.popoverUserTenants {

  .ant-popover-arrow,
  .ant-popover-inner {
    opacity: 0.95;
    background-color: #363636;
  }

  .ant-popover-inner {
    color: white;
    border: none;
    box-shadow: none;

    .popoverProviderAdmin {
      font-size: 12px;
      color: #f4f4f4;
      margin-right: 5px;
      display: inline-block;
      min-width: 70px;
      text-overflow: ellipsis;
      max-width: 70px;
      overflow: hidden;
      margin-top: 10px;
    }

    .popoverTenantName {
      font-size: 12px;
      color: #f4f4f4;
      margin-right: 5px;
    }

    .popoverProviderAdmin {
      max-width: 85px !important;
    }

    .popoverTenantRole {
      border-radius: 2px;
      font-size: 12px;
      color: #6f6f6f;
      background-color: #dfdfdf;
      padding: 3px;
    }
  }

  a:hover.tenantName {
    text-decoration: none
  }
}

.userProviderIcon {
  padding: 3px;
  color: #6f6f6f;
  font-weight: 500;
  font-size: 12px !important;
  border-radius: 2px;
  background-color: #dfdfdf;
  margin: 0 8px 0 5px;
}

.popoverProviderIcon {
  float: right;
  padding: 3px;
  color: #6f6f6f;
  font-size: 12px !important;
  border-radius: 2px;
  background-color: #dfdfdf;
  margin: 7px -2px 0 5px;
}

.tenantIcon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  background-color: #81c784;
  text-align: center;
  color: #ffffff;
  margin-right: 8px;
  font-size: 12px;
}

.listTenant {
  ul {
    margin-bottom: 0;
  }
}