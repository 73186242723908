.terms-wrapper {
  background: white;
  // height: 90vh;
  .terms-section {
    height: 100%;
    overflow-y: auto;
  }
  width: 70%;
  margin-top: 25px;
  min-height: 425px;
  align-self: center;
  margin-left: 15%;
  margin-bottom: 15px;
  font-weight: bold;
}
.center {
  align-self: center;
  text-align: center;
}
.terms-container {
  background: #167fc7;
  padding: 15px;
  min-height: 425px;
}
.no-bg {
  background: none !important;
  .terms-wrapper {
    width: 100%;
    margin-left: 0%;
  }
}

.terms-container.background-polygons {
  .terms-wrapper {
    .modal-body {
      padding: 3rem;
      p {
        font-size: 14px;
        color: #363636;
      }
    }
  }
}
