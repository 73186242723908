.desc-container {
  padding: 5px 15px 15px 10px;
  .title {
    font-weight: 500;
  }
  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 700px;
  }
}
