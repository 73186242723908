.page-header {
  font-family: Rubik, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  border-bottom: dotted 1px #4a90e2;
}
.list-detail-container {
  border: solid 1px #c5d3da;
  border-radius: 4px;
  padding: 15px 0;
  margin: 10px 10px 10px -15px;
}
.server-add-btn {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #4a90e2;
  padding-right: 16px;
}
.server-add-btn:hover {
  cursor: pointer;
}
.server-add-btn :global .anticon-plus {
  padding-right: 5px;
}
.server-add-btn :global .anticon-plus::before {
  width: 17px;
  height: 17px;
  color: #000000;
  font-weight: bold;
}
