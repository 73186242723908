.alert-notification-popover {
  .ant-popover-title {
    height: 60px;
    min-width: 275px;
    background-color: #fcf9f9;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: none;
  }
  .ant-popover-inner-content {
    max-height: 225px;
    width: 100%;
    padding: 0 0 2px 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .popover-notification {
    float: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 51px;
    color: #363636;
    vertical-align: middle;
  }
  .popover-viewAll {
    float: right;
    font-size: 16px;
    font-weight: normal;
    line-height: 51px;
    color: #4a90e2 !important;
    vertical-align: middle;
  }
  .popover-panel {
    display: flex;
    width: 275px;
    height: 60px;
    margin: 0px 0px 2px 0px;
    box-shadow: 1px 1px 0px 1px rgba(211, 211, 211, 0.5);

    .popover-panel--icon {
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .popover-panel--content {
      width: 215px;
      display: flex;
      align-items: center;
      justify-content: left;
      color: #363636;

      .popover-panel--contentTitle {
        width: 100px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .popover-panel-contentDescription {
        width: 150;
        height: 25;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

