.workflow-notification-popover {
  .ant-popover-title {
    height: 60px;
    min-width: 275px;
    background-color: #fcf9f9;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: none;
  }
  .ant-popover-inner-content {
    max-height: 225px;
    width: 100%;
    padding: 0 0 2px 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .popover-workflow {
    float: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 51px;
    color: #363636;
    vertical-align: middle;
  }
  .popover-viewAll {
    float: right;
    font-size: 16px;
    font-weight: normal;
    line-height: 51px;
    color: #4a90e2 !important;
    vertical-align: middle;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  .workflow-item {
    position: relative;
    margin: 0px 0px 2px 0px;
    padding: 18px 10px 18px 35px;
    box-shadow: 1px 1px 0px 1px rgba(211, 211, 211, 0.5);
    background: #ffffff;
    font-size: 16px;
    font-weight: 300;
    color: #363636;
    .status-icon {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
    .prefix-name {
      font-style: italic;
    }
    .object-name {
      font-weight: 500;
    }
  }
}
