.mgmt-svc-conf {
  margin-bottom: 0px !important;
}

.interface-name-div {
  vertical-align: top;
  padding-top: 28px !important;
}

.kubernetes-image-div {
  height: 114px;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 2px 2px 0 0 rgba(217, 226, 237, 0.5);
  border: solid 1px #c5d3da;
  background-color: #ffffff;
  padding: 0 10px;

  .kubernetes-image-list {
    display: list-item;
    padding: 5px 0px;
  }
}

.kubernetes-resource-capacity {
  padding-right: 0px !important;
  padding-left: 10px !important;
}

.kubernetes-hugepages-size {
  padding-right: 0px !important;
}

.kubernetes-icons {
  font-size: 24px;
}

.k8s-namespace ~ div {
  position: relative !important;
}

.k8s-ports {
  padding-left: 0px !important;
}

.k8s-port {
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
}

.k8s-input-port {
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  background: #fff;
  border-style: dashed;
}

.k8s-sriov-driver-dropdown {
  .ant-select-dropdown-menu {
    li {
      height: 25px !important;
    }
  }
}
