.super-admin-nav-img {
  background-image: url(../../../../../../assets/images/getting-started/super-admin-nav.svg);
  width: 574px;
  height: 504px;
}
.super-admin-nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.super-admin-nav-container svg {
  padding: 20px 0px;
}
