.sel-count {
  background-color: #777777;
  padding: 5px 10px;
  height: 32px;
  font-size: 16px;
  border-radius: 19.5px;
  margin-left: 5px;
}

.panel-body-pad {
  padding: 10px 15px;
  div.a10-gui-widgets-panel-body{
    padding: 10px 15px;
  }
}