.display-flex {
  display: flex;
  .monitor-value {
    .condition-container {
      .condition-text {
        display: block;
      }
    }
  }
}
.rowDetails {
  .monitor-value {
    display: inline-block !important;
  }
  .monitor-value.td-truncate {
    max-width: 600px !important;
    vertical-align: middle;
  }
}

.alert-trigger-details {
  background-color: #f8f8f8;
  padding: 10px 20px;
  margin-bottom: 24px;
  width: 100%;
  .section {
    padding: 5px 0px !important;
    margin-top: 5px !important;
    .condition-container {
      white-space: pre-wrap !important;
    }
  }
}
