.roundNumber {
  font-size: 14px !important;
  padding: 1px 7px;
  color: #fff;
  background: #5d5959;
  min-width: 22px;
  display: inline-block;
  height: 22px;
  border-radius: 12px;
  margin: 5px 0 0 5px;
}


