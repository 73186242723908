.workflow-pullup-list {
  table {
    th {
      &:nth-child(2) {
        width: 32px;
      }
    }
  }

  .column-header {
    line-height: 32px;
    .column-title {
      font-size: 14px;
      font-weight: normal;
    }
    .sort-button {
      margin-left: 4px;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .icons-wrapper {
    position: relative;
    text-align: center;
  }

  .text-wrapper {
    .text-inner {
      display: inline-block;
      max-width: 100%;
      font-size: 14px;
      font-weight: 300;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }
  }

  .create-on-column {
    .format-time {
      display: block;
      padding: 6px 0 3px;
    }
    .format-date {
      display: block;
      padding: 3px 0 6px;
    }
  }

  .actions-column {
    //opacity: 0;
    // &:hover {
    //   opacity: 1;
    // }
    text-align: right;
    i {
      margin-right: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }

}

.workflow-pullup-menu {
  .a10-dropdown-menu-popover-list {
    // &::before {
    //   position: absolute;
    //   top: 43px;
    //   width: 100%;
    //   height: 1px;
    //   background-color: #e4e4e4;
    //   content: '';
    // }
    // li:nth-child(2) {
    //   margin-top: 20px;
    // }
  }
}
