.license-guage-bar {
  &-title {
    font-weight: bolder;
  }

  .panel {
    padding: 0 7px;
    margin-bottom: 12px;
  }

  .statsNumberSection {
    background-color: #fff;
    border: 1px solid #e3e3e3;
    text-align: center;
    min-height: 240px;

    .statsFontHeader {
      margin-top: 0.6em;
      font-size: 16px;
      font-weight: 500;
      color: #2a2a2a;
      min-height: 40px;
      font-family: Rubik;
    }
    .statsFontLarge {
      font-size: 36px;
      font-weight: bold;
    }
    .statsFontUnit {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .change-capacity-div {
    text-align: center;
    border: 1px solid #e3e3e3;
    background-color: #fff;
    color: #4ab4ff;
    min-height: 40px;
    label.disabled {
      color: #a0a0a0;
      pointer-events: none;
      cursor: not-allowed !important;
    }
  }
}

.trial-tag {
  font-size: 16px !important;
  width: 100px;
  height: 30px;
  border-radius: 4px;
  margin-top: 5px;
}

.a10-chart-summary-header {
  text-decoration: none;
  font-size: 16px;
  color: #2a2a2a;
  font-weight: 500;
  font-family: Rubik;
}
.a10-chart-summary-header > span {
  font-size: 18px !important;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-10{
  margin-bottom: 10px;
}
.action-button .action-icon{
  margin-right: 5px !important;
}
.area-height{
  height: 92px;
}