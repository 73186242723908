.rowDetails {
  font-size: 14px;
  .heading {
    font-weight: bold;
    margin: 5px -5px;
  }

  .section {
    border-bottom: 1px solid #f7f7f7;
    padding: 15px 0;
    font-size: 14px;
    .block {
      padding: 0 15px;
    }
  }

  .block-sec {
    border-bottom: 1px solid #d7ebff;
  }

  span {
    &.title {
      font-weight: 500;
      margin-right: 15px;
      color: #2a2a2a;
      font-family: Rubik, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial,
        sans-serif;
    }
    &.monitor-value {
      margin-right: 15px;
      b {
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }
}
.a10-gui-widgets-panel-body {
  padding: 30px 70px;
}

.width-400px {
  width: 400px;
}
.width-200px {
  width: 200px;
}

.condition-container {
  display: block;
  .condition-text {
    display: inline-block;
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .condition-value {
    display: inline-block;
    border: 1px solid #dddddd;
    background-color: #fafafa;
    min-width: 35px;
    text-align: center;
    font-size: 12px;
    color: #363636;
  }
}
