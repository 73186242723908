.cluster-device-select {
  border-radius: 4px;
  border: solid 1px #afd7ff;
  margin-top: 1rem;
  width: 80%;
  max-height: 250px;
  overflow: auto;
}

.cluster-tree {
  color: #8c8c8c;
  font: italic !important;
}

.object-tree {
  height: 300px;
  overflow: auto;
  border: 1px solid #afd7ff;
  border-radius: 4px;
  padding: 10px;

  .ant-tree-treenode-switcher-open > .ant-motion-collapse {
    display: table;
  }

  .ant-tree-treenode-switcher-close > .ant-motion-collapse {
    display: none;
  }

  .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #4a90e2;
    .ant-tree-title {
      color: #fff !important;
    }
  }
}

.add-alert-form {
  div[class*='a10-widgets-panel-header-title'] {
    width: 100%;
  }

  .m-r-l-10 {
    margin: 0px 10px;
  }

  .frequency-container {
    display: flex;

    .frequency {
      border: 1px solid #afd7ff;
      border-radius: 4px;
      width: 200px;
      margin: 0px 15px;

      .minutes-trigger {
        margin: 0px;
        padding: 0px;
        max-width: 50%;

        .ant-select-selection {
          border: none !important;

          &:focus {
            border: none !important;
            box-shadow: none;
          }
        }
      }

      .frequency-size {
        max-width: 50%;
        text-align: center;
        border: none;
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

.s-flex {
  display: flex;
  justify-content: space-between;
}

.details-open {
  transform: rotate(0deg);
}

.details-close {
  transform: rotate(-90deg);
}

.trigger-icon {
  margin-right: 10px;
}

.m-l-5 {
  margin-left: 5px;
}

.first-action {
  width: calc(100% - 44px) !important;
}

.tree-selection {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
}

.disabled-search {
  input {
    border: 1px solid #d2d2d2 !important;
  }
}

.tree-icon {
  margin-right: 7px;
  width: 20px !important;
  height: 20px !important;

  span {
    color: #fff !important;
    font-size: 14px !important;
  }
}

.tree-advance {
  .ant-tree-checkbox-disabled {
    display: none;
  }
}

.ant-tree {
  .ant-motion-collapse {
    overflow: unset;
  }
}

.tree-basic {
  .ant-tree-switcher {
    display: none !important;
  }
}

.notificationFrequency_options {
  margin-top: 12px;
  .n_option {
    margin-bottom: 15px;
  }
  .special_input {
    margin: 0px 10px;
  }
}

.custom-tooltip-container {
  max-width: 400px;
  width: 400px;
}
.weebhook-container {
  margin-top: 16px;
}

.action-title {
  font-weight: bold;
}
