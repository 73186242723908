#iconDim {
  padding-left: 8px;

  img {
    width: 30px !important;
    height: 30px !important;
    background-size: 30px !important;
  }
}

.titleAcc {
  padding-left: 20px;
}

.k8sBearerToken {
  position: absolute;
  right: -12px;
}
