@mixin subMenuHighlight {
  .menu-icon {
    background-color: #0082d6;
  }
  font-weight: bold;
}

.pt-drop-container {
  text-align: center;

  @media (max-width: 777px) {
    .provider-tenant-menu,
    .provider-tenant-menu-collapsed {
      width: 100px;
    }
  }
  @media (min-width: 777px) and (max-width: 1309px) {
    .provider-tenant-menu,
    .provider-tenant-menu-collapsed {
      width: 130px;
    }
  }
  @media (min-width: 1310px) {
    .provider-tenant-menu,
    .provider-tenant-menu-collapsed {
      width: 175px;
    }
  }

  .provider-tenant-menu {
    // margin-top: 10px;
    margin-top: -5px !important;
    margin-right: 18px;
  }

  .provider-tenant-menu-collapsed {
    // width: 75px;
    margin-top: -5px !important;
    margin-right: 18px;
  }

  .provider-tenant-menu,
  .provider-tenant-menu-collapsed {
    &.ant-select {
      .ant-select-selection {
        height: 30px;
        min-height: 25px;
        background-color: #fff;
        border-radius: 4px;
        border: solid 1px #c5d3da;
        font-size: 14px;
        color: #1b1b1b;
      }

      .ant-select-selection__rendered {
        position: relative;
        line-height: inherit !important;
        margin: 3px 24px 2px 11px;
      }

      .ant-select-selection__placeholder {
        color: #1b1b1b;
        margin: 0 0 0 -5px;
        transform: translateY(-50%);
        span {
          vertical-align: middle;
        }
        .option-label {
          margin-left: 3px !important;
          color: #1b1b1b;
        }
      }

      .ant-select-arrow {
        color: #4a4a4a;
        i.anticon-down svg,
        i.anticon-down svg {
          color: #4a4a4a;
        }
      }

      .ant-select-selection-selected-value {
        margin: 0 0 0 -5px;
        min-height: inherit !important;
        line-height: inherit !important;
        span {
          vertical-align: middle;
        }
        .option-label {
          margin-left: 3px !important;
          color: #1b1b1b;
        }
      }
    }
  }
}

.provider-tenant-select-dropdown {
  width: 250px !important;
  position: absolute;
  top: 40px;
  z-index: 1000;
  width: 100%;
  border-radius: 2px;
  box-shadow: 3px 3px 0 0 rgba(211, 211, 211, 0.5);
  border: 1px solid #e4e4e4;
  background-color: #fff;
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-top: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4;
  }
  .ant-select-tree-title {
    span {
      vertical-align: middle;
    }
    .option-label {
      margin-left: 3px !important;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
    }
  }
  .ant-select-dropdown-search {
    display: block;
    padding: 4px;
    .ant-select-search__field__wrap {
      width: 100%;

      input {
        padding: 4px 7px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #d2d2d2;
        border-radius: 4px;
        outline: none;
      }
    }
  }
}
