.workflow-pullup-detail {
  .detail-row {
    padding: 20px 0;
    &.bottom-divide-line {
      border-bottom: 1px solid #d0e2f5;
    }

    .infor-label {
      padding-right: 15px;
      font-size: 14px;
      font-weight: normal;
    }

    .infor-text {
      max-height: 300px;
      overflow: auto;
      padding-right: 15px;
      font-size: 14px;
      font-weight: 300;
      word-break: normal;
      white-space: pre-wrap;
      &.message {
        padding: 10px;
        border-radius: 4px;
        border: solid 1px #d2d2d2;
        background-color: #f8f8f8;
      }
      &.cli {
        padding: 10px;
        border-radius: 4px;
        border: solid 1px #808080;
        background-color: #686868;
        font-family: AndaleMono;
        color: #ffffff;
      }
    }

    .json-code {
      border-radius: 4px;
      border: solid 1px #d2d2d2;
      background-color: #f8f8f8;
    }
  }
}
