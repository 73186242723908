.actionName {
  font-weight: bold;
  margin-right: 10px;
  color: #363636;
  padding-left: 16px;
}

.emailToSupport {
  font-size: 14px;
  font-weight: 300;
  color: #363636;
  padding-left: 24px;
}

.padLft {
  padding-left: 18px !important;
}

.padRgt {
  padding-right: 24px !important;
}

.pLR15 {
  padding: 0px 15px;
}
