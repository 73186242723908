.post-url-container {
  div[class^='a10-widgets-panel--'] {
    border: none;
  }
}

.custom-action-label {
  margin-left: 13px;
  display: inline-flex;
  vertical-align: text-top;
}
