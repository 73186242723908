.all-available-HC-app {
  width: 22px;
  height: 21px;
  font-size: 18px;
  color: #0078c3;
  padding-right: 12px !important;
  position: relative;
  top: -5px !important;
}

.apps-list-padding {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.apps {
  .vertical-split-divider {
    border-left: 1px dashed #d2d2d2;
  }

  .mgnRight {
    margin-left: 15px;
  }

  .pdRight {
    padding-right: 22px;
  }
}
.comment-height{
  height: 99px !important;
}