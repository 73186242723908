.sort-button {
  display: inline-block;
  .up-button {
    line-height: 0;
    margin-bottom: 2px;
    .up-text {
      margin-left: 4px;
    }
  }
  .down-button {
    line-height: 0;
    margin-top: 2px;
    .down-text {
      margin-left: 4px;
    }
  }
}
