/** could change theme **/
.stats-ongoing {
  background-color: #7ed321 !important;
}
.stats-stop {
  background-color: #ff2e48 !important;
}
.stats-warning {
  background-color: #ffba47 !important;
}
.stats-header {
  background-color: #ffffff !important;
  border: solid 1px #e7ecf1 !important;
  border: solid 1px var(--pale-grey);
}
.status-icon {
  width: 16px !important;
  height: 16px !important;
}
.status-icon :global .ant-avatar-string {
  line-height: 17px !important;
  left: calc(50% - 5px);
}
.text-with-avatar {
  display: flex;
  align-items: center;
}
.text-with-avatar > span:nth-child(1) {
  margin-right: 5px;
}
.hc-list table {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.hc-list table tr.ant-table-expanded-row > td:nth-child(2) {
  padding: 0 !important;
}
.hc-list table tr th {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363636;
}
.hc-list table tr th span {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363636;
}
.hc-list table tr td {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363636;
}
.hc-list table tr td a {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a90e2;
}
.hc-list .ant-spin-container.ant-spin-blur {
  opacity: 0.4;
  padding-bottom: 25px;
  filter: blur(1.9px);
}
.hc-list .loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.hc-list .ant-collapse-content.ant-collapse-content-active {
  overflow: visible;
}
.hc-list .ant-collapse-item.ant-collapse-item-active {
  border: 0;
}
.hc-list .ant-collapse-item {
  border: 0;
}
.top-list {
  margin-bottom: 32px;
}
.top-list ul.ant-pagination {
  margin: 16px 0 -16px !important;
}
.show-dirty-list-inline {
  margin-left: 5px;
  margin-right: 5px;
}
.show-dirty-list-outside-left {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-120%, -50%);
}
.show-dirty-list-outside-right {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(120%, -50%);
}
.list-detail-container {
  margin: 16px 16px 16px -16px !important;
}
.detail-section {
  margin: 0 16px;
  padding: 0 0 16px;
}
.detail-section :global .ant-collapse-item {
  border: 0px !important;
  border-bottom: 0px !important;
}
.detail-section div.ant-collapse.ant-collapse-borderless div[role='tabpanel'] {
  overflow: visible;
  padding: 0;
}
.detail-section div.ant-collapse.ant-collapse-borderless div[role='tabpanel'] > div {
  padding: 0 !important;
}
.detail-section div.ant-collapse.ant-collapse-borderless .ant-collapse-item {
  border: 0px !important;
  border-bottom: 0px !important;
}
.detail-section div.ant-collapse.ant-collapse-borderless:first-child {
  margin: 32px 0 0;
}
.detail-section div.ant-collapse.ant-collapse-borderless:nth-last-child(n + 2) div[role='tabpanel'] {
  margin-bottom: 16px;
  padding: 0 0 16px;
}
.detail-section ul.ant-pagination {
  margin: 16px 0 -16px !important;
}
.detail-section .stats-bar-container {
  margin: 50px 0px 16px;
}
.section-title-container .ant-badge-count {
  min-height: 16px !important;
  min-width: 16px !important;
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
  padding: 0 5px;
  background-color: #b3b3b3;
}
.section-title-container .ant-scroll-number-only > p {
  font-size: 10px;
}
.section-title-container-no-wrap {
  padding: 12px 0 12px 40px;
  color: #363636;
}
.section-title-container-no-indent {
  padding: 12px 0 12px 0;
  color: #363636;
}
.section-title {
  font-weight: normal;
  padding-left: 10px;
  padding-right: 10px;
}
.section-title-warning {
  padding-left: 10px;
  padding-right: 10px;
  color: #e30000;
}
.stats-bar-container {
  margin: 50px 16px 16px;
}
.section-row {
  padding-left: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.section-row-compact {
  padding-left: 15px;
  padding-top: 15px;
}
.text-block {
  padding-left: 15px;
  padding-right: 15px;
}
.text-block .block-title {
  font-weight: bold;
  margin-right: 10px;
}
.text-block .block-value {
  color: #363636;
}
body {
  background-color: #fafbfc;
  font-family: Segoe UI, Lato, -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #2a2a2a;
}
/** scrollbar override **/
* {
  /* total width */
  /* background of the scrollbar except button or resizer */
  /* scrollbar itself */
  /* set button(top and bottom of the scrollbar) */
  /* for supporting Firefox */
  scrollbar-color: #babac0 transparent;
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*:hover::-webkit-scrollbar-thumb {
  background-color: #babac0;
}
*::-webkit-scrollbar-track {
  background-color: transparent !important;
}
*::-webkit-scrollbar-thumb {
  border-radius: 8px;
}
*::-webkit-scrollbar-button {
  display: none;
}
/** override style of pace-progress **/
.pace .pace-activity {
  display: none;
}
/** table related **/
.table-dropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.slide-pane__content {
  background-color: #f9f9f9;
}
.action-button {
  border: none;
  background: none;
  font-size: 16px;
  color: #4a90e2;
  height: 32px;
  border-radius: 19.5px;
  border: solid 1px rgba(255, 255, 255, 0);
  margin-left: 5px;
  margin-right: 5px;
}
.action-button:disabled {
  border: solid 1px rgba(255, 255, 255, 0);
  background: none;
}
.action-button:disabled .fa {
  color: #efefef;
}
.action-button:hover,
.action-button:focus {
  height: 32px;
  background-color: #ffffff;
  border: solid 1px #d0e2f5;
}
.action-button .action-icon {
  color: #000;
  margin-right: 10.5px;
  vertical-align: middle;
}
.table-header {
  float: right;
}
.table-header .vertical-split-divider {
  border-left: 1px dashed #d2d2d2;
  height: 40px;
  width: 2px;
  margin: 0px 20px;
}
.table-header-inner {
  margin-bottom: 12px;
}
.inner-table {
  margin-top: 12px;
}
.searchbar-container {
  display: inline-block;
}
.ant-popover .ant-menu {
  list-style-type: none;
  margin: 0 -10px;
  padding: 0;
  min-width: 150px;
  color: #525151;
  border: none;
}
.ant-popover .ant-menu .ant-menu-item {
  cursor: pointer;
  border: 1px solid #e4e4e4;
  padding: 0 4px;
  height: 34px;
  line-height: 34px;
}
.ant-popover .ant-menu .ant-menu-item:hover {
  color: #363636;
  border-radius: 2px;
  background-color: #d1e7fd;
  border: 1px solid #afd7ff;
}
.a10-collapse-list .ant-collapse {
  border: none;
  background-color: #ffffff;
}
.a10-collapse-list .ant-collapse-item {
  background-color: #f1f8ff;
  margin-bottom: 9px;
  border: 1px solid #e7ecf1;
  border-radius: 4px;
}
.a10-collapse-list .ant-collapse-item:last-child {
  margin-bottom: 0;
}
.a10-collapse-list .ant-collapse-content {
  background-color: unset;
  border-top: none;
  overflow: unset;
}
.a10-report-collapse .ant-collapse-header {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.a10-report-collapse .ant-collapse-header .arrow {
  margin-top: 14px;
}
.a10-normal-collapse .ant-collapse-header {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.a10-normal-collapse .ant-collapse-header .arrow {
  margin-top: 0px;
}
.a10-normal-collapse .ant-collapse-item {
  border: none;
}
.a10-table-single {
  margin-top: -55px;
}
.a10-table-single .ant-table table {
  border-collapse: collapse;
}
.a10-table-single .ant-table table thead {
  border: none;
}
.a10-table-single .ant-table-tbody > tr {
  background: none;
}
.a10-table-single .ant-table-tbody > tr > td {
  border: none;
}
.a10-table-single .ant-table-thead > tr {
  background: #ffffff00;
  border: none;
}
.a10-table-single .ant-table-thead > tr > th {
  background: #ffffff00 0 !important;
  font-size: 12px;
  color: #a6a6a6;
  padding: 8px;
  border: none;
}
.a10-table-single .ant-table-thead > tr > td {
  border: none;
}
.pullup-container .search-input-container {
  margin: unset;
  box-shadow: none;
  border-top: none;
}
.row.navbar.subHeader + .row.no-margin.sectionContainer {
  padding-top: 30px;
}
.ant-row-flex.ant-row-flex-middle.action-container + .col-md-12 {
  padding: 0px;
}
.ant-tree {
  min-height: 46px;
}
.ant-upload > button > .anticon-upload + span {
  margin-left: 18px;
}
.ant-collapse.marginTop20.marginBottom15 > .ant-collapse-item .ant-collapse-header .anticon-right.arrow {
  margin-top: 20px;
}
.ant-collapse.marginTop20.marginBottom15 > .ant-collapse-item .ant-collapse-header + .col-md-12 productHeader.inline .col-md-4.no-padding + .col-md-8.appLauncherDiv {
  padding-top: 8px;
}
.action-container + .ant-table-wrapper .ant-table.ant-table-small + ul.ant-pagination.mini.ant-table-pagination > li.ant-pagination-prev,
.action-container + .ant-table-wrapper .ant-table.ant-table-small + ul.ant-pagination.mini.ant-table-pagination > li.ant-pagination-next {
  position: relative;
  bottom: 2.5px;
}
.ant-tree-node-content-wrapper {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lp-assigned {
  width: 62px;
  height: 22px;
  border-radius: 11px;
  background-color: #81c784;
  margin-left: 15px;
  padding: 5px 5px;
  font-size: 12px;
  font-weight: normal;
}
.alert-text {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363636;
  margin-right: 5px;
}
.more-info-icon {
  display: inline-block;
  width: 16px;
  height: 14px;
  background-image: url('../assets/images/svg/hc-icons/more-info-inactive.svg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 16px 14px;
  cursor: pointer;
}
.more-info-icon:hover {
  background-image: url('../assets/images/svg/hc-icons/more-info-active.svg');
}
.anticon:before {
  content: none;
}
.highcharts-tooltip-container {
  z-index: 3000;
}
.dashboard-timer {
  z-index: 99;
}
