.provision-panel {
  background-color: #FFFFFF;

  .panel-header {
    height: 60px;
    line-height: 60px;
    border-bottom: solid 1px #f2f2f2;
    padding: 0 14px;

    .title {
      line-height: 60px;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: #363636;
      float: left;
      width: 100%;

      span i {
        vertical-align: middle;
        margin-right: 14px;
      }

      .all-tenant {
        margin-top: 10px;
      }
    }
  }

  .panel-body {
    padding: 30px 70px;
  }
}

.custom-provision-head {
  position: absolute;
  left: 8px;
  top: 0px;
}

.custom-bulk-apply-head {
  position: absolute;
  left: 55px;
  margin-left: -10px;
}
