.statusIconTenantText {
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  color: white;
  line-height: 27px;
}

.partitionTitle {
  font-size: 18px;
}