.font20 {
  font-size: 20px;
}

.font14 {
  font-size: 14px;

  .ant-select-selection-selected-value {
    font-size: 14px;
  }

  .ant-select-dropdown-menu-item {
    font-size: 14px;
  }
}

.caution-div {
  margin-top: 20px;

  .btn-div {
    button {
      margin: 0 15px;
    }
    .cancel-btn {
      background-color: #e3e3e3;
    }
  }
}

.log-capacity {
  &:hover {
    border-bottom: 1px dashed #4a90e2;
  }
}

.log-pc-popoverinfo {

  .ant-tooltip-content {
    .ant-tooltip-arrow::before {
        background-color: #fff
      
    }
    .ant-tooltip-inner {
      background-color: #fff;
      box-shadow: 2px 2px 0 0 rgba(217, 226, 237, 0.5);
      color: #363636;
      border: solid 1px #c5d3da;
    }
  }

  .log-pc--title {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #c5d3da;
    margin-bottom: 10px;
    padding: 5px;
  }

  .log-pc--text {
    font-size: 14px;
    font-weight: normal;
  }
}

.log-slider {
  padding-top: 30px;
  .ant-slider {
    width: 88% !important;
    margin-left: 0px !important;
    padding-top: 8px !important;
  }
  .ant-slider-round .ant-slider-rail,
  .ant-slider-round .ant-slider-track,
  .ant-slider-round .ant-slider-step {
    height: 8px;
    border-radius: 8px;
  }
  .ant-slider-step {
    .ant-slider-dot {
      top: -1px;
      width: 10px;
      height: 10px;
    }
  }
  .ant-slider-handle {
    top: 11px;
  }
  .ant-slider-mark {
    font-size: 10px;

    .ant-slider-mark-text {
      &:first-child {
        top: -45px;
      }
      &:nth-child(3) {
        top: -45px;
      }
      &:last-child {
        left: 110% !important;
        top: -18px;
        width: 60px;
      }
    }
  }
}
.capacity-row {
  padding: 10px 0;

  .capacity-wrapper {
    height: 12px;
    border-radius: 2px;
    margin-top: 5px;
  }
  .max-capacity {
    background-color: #81c784;
  }
  .system-capacity {
    background-color: rgb(195, 203, 207);
    width: 100%;
  }
  .shared-capacity {
    background-color: #64b5f6;
  }
  .allocated-capacity {
    background-color: #6cd1dd;
  }
}

