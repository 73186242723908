.clusters-list {
  min-height: 390px; }
  .clusters-list table tbody tr.active-row {
    background: #fff; }
    .clusters-list table tbody tr.active-row > td {
      border-top: 0px; }
      .clusters-list table tbody tr.active-row > td:nth-child(1) {
        border-left: 4px solid #c1e9ff; }
  .clusters-list .fa {
    color: #afbbc2;
    width: 15px;
    text-align: center;
    cursor: pointer; }
