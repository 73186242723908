.display-block {
  display: block;
}

.display-none {
  display: none;
}

.thunderIpAddressDevice {
  position: absolute;
  float: right;
  margin-left: -0.8em;
}

.create_new_cluster {
  font-weight: 500;
  border-bottom: solid 1px #f2f2f2;
  padding-bottom: 3px;
}

.add-another-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  min-height: 42px;
  color: #4a90e2;
  &:hover,
  &:focus {
    color: #4a90e2;
  }

  .a10-widget-appicon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border: 1px solid #afd7ff;
    border-radius: 50%;
    svg {
      stroke-width: 2;
    }
  }
}