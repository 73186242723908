// @import './header';

body {
  margin: 0;
  padding: 0;
}

// header / subheader
// .subHeader{
//   background-color: rgb(250, 250, 250);
// }

@mixin select-down-arrow-menu() {
  i.anticon-down {
    top: 50%;
    svg {
      font-size: 12px !important;
      color: white;
    }
  }
}

@mixin product-down-arrow-menu() {
  i.anticon-down {
    top: 50%;
    svg {
      font-size: 16px !important;
      color: white;
    }
  }
}

.menu-container,
.user-selector {
  .ant-select-arrow {
    @include select-down-arrow-menu();
  }
  span.ant-select-arrow {
    top: 50%;
  }

  i.anticon {
    &.anticon-down,
    &.anticon-up {
      &.updown-icon {
        top: 15px;
        position: relative;
        svg {
          font-size: 12px;
        }
      }
    }
  }
  // i.anticon.anticon-down.updown-icon{
  //   top: 15px;
  //   svg {
  //     font-size:12px;
  //   }

  // }

  @include select-down-arrow-menu();
}

.product-dropdown {
  .ant-select-arrow {
    @include product-down-arrow-menu();
  }
  span.ant-select-arrow {
    top: 50%;
  }

  i.anticon {
    &.anticon-down,
    &.anticon-up {
      &.updown-icon {
        top: 15px;
        position: relative;
        svg {
          font-size: 16px;
        }
      }
    }
  }

  @include product-down-arrow-menu();
}

.ant-table-thead > tr > th.ant-table-column-sorters {
  font-size: 16px;
}

div.ant-table-column-sorters {
  ::before,
  :before {
    content: none;
  }
}

.action-button {
  .action-icon {
    position: relative;
    // bottom: 3px;
    bottom: 1px;
    margin-right: 5px;
  }
}

.submit-button {
  width: 160px !important;
  height: 46px !important;
  padding: 0 !important;
}

.validate-button {
  width: 160px !important;
  height: 46px !important;
  padding: 0 !important;
  margin-left: 8px;
  background-color: #ffffff;
}

.cancel-button {
  width: 160px !important;
  height: 46px !important;
  padding: 0 !important;
  margin-left: 8px;
}

.ant-checkbox-wrapper {
  width: auto !important;
}
