.device-detail-container {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 33px;
  margin-right: 12px;

  .ant-collapse {
    background-color: #ffffff;

    .ant-collapse-item {
      // border-bottom: none;
      padding-left: 0px !important;

      &-active {
        border-left: 1px solid #cfd9e2 !important;
      }

      .ant-collapse-header {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        padding-left: 24px;
      }
    }
  }

  .device-details {
    color: #000000;
    font-family: Segoe UI;
    .dev-content {
      display: flex;
      .device-title {
        font-family: Roboto;
      }
    }
  }

  .device-overview {
    &-title {
      font-family: Rubik;
      font-weight: normal;
      margin-bottom: 10px;
    }
    &-row {
      & > .ant-col {
        padding: 0 12px 0 27px;

        &:not(:last-child) {
          border-right: 1px dashed #c2cad8;
        }

        &:first-child {
          padding-left: 4px;
        }

        &:last-child {
          padding-right: 4px;
        }
      }
    }
    &-card-item {
      padding: 10px 0;
      &-title {
        font-family: Roboto;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &-content {
        font-family: Segoe UI;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-state {
      display: flex;
      align-items: center;
    }
  }

  .device-card-view {
    &-title {
      font-family: Rubik;
      font-weight: normal;
      border-bottom: 1px dashed #4a90e2;
      margin-bottom: 5px;
    }
    &-row {
      &:not(:nth-child(2)) {
        padding-top: 6px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #eff3f7;
      }
    }
    &-card-item {
      padding-right: 5px;
      &-title {
        font-family: Roboto;
        font-weight: 500;
        padding-top: 17px;
      }
      &-content {
        font-family: Segoe UI;
        padding-top: 19px;
        padding-bottom: 27px;
      }
    }

    &-state {
      display: flex;
      align-items: center;
    }
  }
}

.device-content {
  & > div:first-child {
    margin-bottom: 16px;
  }

  .ant-collapse-header {
    cursor: pointer !important;

    span {
      font-weight: normal !important;
      .ant-scroll-number-only {
        font-size: 12px !important;
      }
    }
  }
  .edit-content {
    float: right;
    font-size: 16px !important;
    font-weight: 400;
    cursor: pointer;
    color: #4a90e2;
  }

  div {
    .ant-collapse-header,
    .ant-collapse-item {
      background-color: #ffffff;
    }
  }
}

.device-card {
  font-size: 14px;

  &-inner {
    padding-right: 15px;
    padding-left: 15px;
    border: 1px solid #cfd9e2;
    border-radius: 4px;
  }

  &-title {
    font-family: Rubik;
    font-weight: normal;
    margin-top: 15px;
    margin-bottom: 0px !important;

    &-text {
      border-bottom: dashed 1px #4a90e2;
    }
  }
}

.warning-lic-class {
  color: orange !important;
}

.danger-lic-class {
  color: red !important;
}

.device-detail-delete-confirm {
  .ant-modal-footer {
    .ant-btn-primary {
      margin-right: 10px;
      background-color: #e57373;
      border-color: #e57373;
    }
  }
}

.device-partition-selection {
  width: 200px;
  margin-left: 25px;
  .ant-select-selection__rendered {
    margin-left: 3px;
    height: 24px;
  }

  .ant-select-selection {
    color: #858ea4;
    border-color: #c5d3da;
    height: 26px;
  }

  &-avatar {
    .ant-avatar-string {
      color: white;
    }
  }
}

.fontWeight500 {
  font-weight: 500;
}

.fontSize16 {
  font-size: 16px;
}
