.process-chart-container {
  position: relative;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  svg.highcharts-root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: visible;
  }
}
