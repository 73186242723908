.apps {
    .app-list {
        background: #FFF;
        border-radius: 4px;
        border: 1px solid #EBEBEB;
        min-height: 300px;
        overflow: hidden;

        .info-header {
            padding: 15px;
        }

        .app {
            background-color: #f3f9ff;
            padding: 25px 0 25px 25px;
            border-radius: 4px;
            height: 252px;
            margin: 7px;

            &.list {
                min-height: 95px;
                max-height: 95px;
                padding: 10px 0 10px 10px;
            }

            .details-section {
                color: #a9a9a9;
                font-size: 12px;

                .app-title {
                    color: #000;
                    font-size: 18px;

                    .app-version {
                        color: #a9a9a9;
                        font-size: 12px;
                        margin-left: 3px;
                    }
                }

                .app-desc {
                    font-size: 14px;
                    margin: 10px 0px;
                    color: #9b9b9b;
                    font-weight: 300;
                    height: 90px;
                    overflow-y: auto;
                    word-wrap: break-word;
                }
            }

            .actions {
                z-index: 999;
                right: 15px;
                top: 15px;

                .fa-ellipsis-v {
                    padding: 2px 5px;
                }

            }

            .dropdown-menu {
                right: 15px !important;
                top: 38px !important;
                padding: 0px;
            }

            &:hover {
                background-color: #e6f3ff;

                .actions {
                    display: block;
                }
            }
        }

        .installBtn {
            position: relative;
            width: 100%;
            min-height: 1px;
            padding-right: 15px;
            font-size: 14px;
            font-weight: 500;
            top: 4px;
        }

        .elipsesBtn {
            position: relative;
            float: right;
            left: -7.6%;
            top: 12%;
            width: 10px;
            height: 10px;
        }

        #contentApp {
            padding-top: 6% !important;
        }

    }

    .app-content-icon {
        height: 60px;
        width: 60px;
        background-size: 50px;
        background-repeat: no-repeat;
        margin-top: 5px;
        display: inline-block;
    }

    .a10-icon {
        height: 28px;
        width: 26px;
    }
}