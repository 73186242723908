.license-guage-bar {
  &-title {
    font-weight: bolder;
  }

  .panel {
    padding: 0 7px;
    margin-bottom: 12px;
  }

  .statsNumberSection {
    background-color: #fff;
    border: 1px solid #cfd9e2;
    border-radius: 4px;
    text-align: center;
    min-height: 240px;

    .statsFontHeader {
      margin-top: 0.6em;
      font-size: 14px;
      font-weight: normal;
      color: #2c2c2c;
      min-height: 40px;
    }
    .statsFontLarge {
      font-size: 36px;
      font-weight: bold;
    }
    .statsFontUnit {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .change-capacity-div {
    text-align: center;
    background-color: #fff;
    border: 1px solid #cfd9e2;
    background-color: #fff;
    color: #4ab4ff;
    min-height: 40px;
    label.disabled {
      color: #a0a0a0;
      pointer-events: none;
      cursor: not-allowed !important;
    }
  }

  .a10-chart-summary {
    border-radius: 4px;
    border: 1px solid #cfd9e2;
  }
}

.trial-tag {
  font-size: 12px !important;
  width: 84px;
  margin-top: 5px;
}
