.metricDropdown {
  .ant-select-selection__rendered div.ant-select-selection__choice__content {
    margin-right: 0px !important;
    padding-right: 3px !important;
  }
}
.addAnotherConditionContainer {
  padding: 10px;
  border: 1px solid #d2d2d2;
  text-align: center;
  margin-bottom: 15px;
}
.connectionLink {
  height: 15px;
  width: 1px;
  text-align: center;
  margin: 0 auto;
  border: 1px solid #d2d2d2;
}
.section-header {
  text-align: center;
  .ant-select-selection-selected-value {
    width: 100% !important;
    padding-right: 0px !important;
  }
}
.selectedCondition {
  padding: 8px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #d2d2d2;
  max-width: 100px;
  text-transform: capitalize;
}
.metricDropdownContainer {
  display: inline-block;
  width: 98%;
}
.metricDropdownRemove {
  width: 0%;
  float: right;
}
.link {
  cursor: pointer;
  color: #4a90e2;
}
.threshold-heading {
  border-bottom: 1px solid #f2f2f2;
  margin-right: -70px;
  label {
    margin-bottom: 0 !important;
  }
}
.a10-trigger-icon {
  width: 32px !important;
  height: 32px !important;
  background-size: 32px !important;
}
.addMoreLink {
  top: -5px;
  position: relative;
}
.add-condition-button {
  margin-bottom: 24px;
  cursor: 'pointer';
}
.TriggerAddFormCls {
  div[class^='a10-widgets-panel--'] {
    border: 1px solid #e7ecf1;
  }
}
.thresholdContainer {
  margin-top: 10px;
}
.thresholdContainer + .thresholdContainer {
  margin-top: 0px;
}
