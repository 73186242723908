#actionDetailsText {
  margin-right: 30px;
  border-bottom: 1px solid #d7ebff;
}
.rowDetails {
  .monitor-value {
    display: inline-block !important;
    white-space: normal;
  }
  .monitor-value.td-truncate {
    max-width: 300px;
  }
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  .title {
    vertical-align: top;
  }
}

.alert-action-details {
  margin-top: -60px;
  background-color: #f8f8f8;
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 24px;
  .section {
    padding: 5px 0px !important;
    margin-top: 5px !important;
  }
  .w100 {
    width: 100%;
  }
  .m-l-15 {
    margin-left: 15px;
  }
}
