.custom-tooltip-lm-radio {
  left: 20px;
}

.license-details {
  color: #000000;

  .license-content {
    display: flex;

    .license-title {
      font-weight: normal;
      color: #000000;
    }
    .license-desc{
      font-family: 'Rubik';
      color: #2a2a2a;
      padding-top: 2px;
    }
  }
}

.license-table-div {
  margin-top: 25px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.action-items-div {
  display: flex;
  float: right;

  .filter-div {
    margin-right: 10px;

    .ant-select {
      width: 150px;
    }
  }
}
.list-panel {
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #e3e3e3;
  height: 87px;
  line-height: 52px;
}
.table-header-color{
  color: #000000 !important;
}
.lic-info{
  font-family: Rubik;
  font-weight: 500;
}
.license-info{
  font-family: Roboto;
  font-weight: 300;
  color: #000000;
}
.ant-form-item-label > label {
  color: #000000;
}
.label-border{
  border-bottom: dashed 1.5px #4a90e2;
  padding-bottom: 4px;
}
.copy-button{
  margin-left: 0 !important;
  padding-left: 0 !important;
}