.sessn-mgr-title {
  font-size: 26px;
  font-weight: bold;
  text-align: left;
  color: #363636;
}

.sessn-mgr-description {
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  color: #363636;
  margin-bottom: 23px;
}

.sessn-mgr-drawer .ant-drawer-wrapper-body {
  background-color: #f6f9fe;
}

.sessn-mgr {
  /* border: 1px solid #e7ecf1; */
  background-color: #f6f9fe;
  border-radius: 4px;
}

.sessn-mgr-body-wrapper {
  border: solid 1px #e7ecf100;
  margin-top: 20px;
  background-color: white;
}

.sessn-mgr-body-wrapper > .panel-header {
  height: 60px;
  line-height: 60px;
  padding: 0 14px;
  border-bottom: solid 1px #e7ecf1;
}

.sessn-mgr-body-wrapper > .panel-header > .title {
  width: fit-content;
  line-height: 60px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  float: left;
}

.sessn-mgr-body-wrapper > .panel-header > .title > .title-pic {
  stroke: rgb(56, 56, 56);
  width: 22px;
  height: 22px;
  margin-right: 12px;
}

.sessn-mgr-config {
  padding: 30px 0px 30px 30px;
  /* border-bottom: solid 1px #e7ecf1; */
  margin: 50px 14px;
}

.session-duration {
  margin-right: 20px;
}

.sessn-mgr-btn.extend {
  margin-right: 20px;
}

.extend-session {
  left: 8px;
  position: relative;
}

.extend-session-container {
  background: #ffffff;
  width: 20px;
  height: 19px;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 5px;
  position: relative;
  color: #005f4a;
  font-size: 12px;
  font-weight: 500;
}

.extend-session-container > i {
  bottom: 2px;
  position: relative;
}

.extend-session-container > i > svg {
  font-size: 12px !important;
}

.sessn-mgr-btn {
  /* padding: 5px 45px !important; */
  width: 160px !important;
  height: 46px !important;
}

.sessn-mgr-title {
  padding: 24px 24px 0px 24px !important;
}

.sessn-mgr-body {
  padding: 24px 24px 14px 24px !important;
  flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
}

.sessn-mgr-drawer .ant-drawer-body {
  padding: 0px !important;
  background-color: #f6f9fe !important;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.sessn-mgr-footer {
  -webkit-flex: 0 0 72px;
  flex: 0 0 72px;
  background-color: #f4f4f4;
  padding: 24px 42px;
  box-shadow: 0 -1px 4px 0 hsl(0deg 0% 77% / 50%);
  margin-top: 80px;
}
