.main-footer {
  background-color: #edf1f9;
  position: fixed;
  bottom: 0;
  height: 20px;
  width: calc(100% - 240px);
  transition: width 0.2s;
  z-index: 998;

  &.left-nav-collapsed {
    width: calc(100% - 52px);
  }

  .copyright {
    color: #363636;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    text-align: right;
    margin: 3px 5px 0;
    opacity: 0.65;
  }
}

.login-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: transparent;
  color: #dbdbdb;

  .product-version {
    margin-bottom: 22px;
    font-size: 12px;
    color: #fff;
    text-align: center;
  }

  .copyright {
    margin-bottom: 6px;
    font-size: 12px;
    text-align: center;
  }

  .footer-nav {
    font-size: 12px;
    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      li {
        padding: 0 2px;
        a {
          color: #dbdbdb;
          text-decoration: none;
        }
        a:hover {
          color: #4a90e2;
        }
      }
    }
  }
}
