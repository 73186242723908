// @import '../containers/Controller/styles/_controller';

.inline {
  display: -webkit-inline-box !important;
}

#root {
  // overflow: hidden;
  height: 100%;
}

@keyframes example {
  0% {
    background-color: red;
    left: 0px;
    top: 0px;
  }

  25% {
    background-color: yellow;
    left: 200px;
    top: 0px;
  }

  50% {
    background-color: blue;
    left: 200px;
    top: 200px;
  }

  75% {
    background-color: green;
    left: 0px;
    top: 200px;
  }
}

.loading {
  background-image: url('../assets/images/spinner.gif') no-repeat;
  background-color: rgb(66, 136, 164);
  // animation-name: example;
  // animation-duration: 4s;
  // animation-fill-mode: forwards;
  color: white;
  text-align: center;
  // min-height: 500px;
  //margin-top: 25%;
  // padding-top: 15%;
}

.alertBox {
  margin-left: 10px;
}

.alertBox {
  display: block;
  opacity: 1;
  margin: 0 auto 20px;
  max-width: 420px;
  /* display: none; */
  word-wrap: break-word;
  word-break: break-word;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: opacity 0.15s linear;
  z-index: 2056;
  position: fixed;
  top: 12px;
  left: 70%;
  width: 25%;
  display: -webkit-inline-box;
}

.alert-info {
  background-color: #3697d3;
  color: #153e7b;
}

.alert-danger {
  background-color: #ebcdcd;
  color: #cb6042;
}

.alert-success {
  background-color: rgb(50, 174, 91);
  color: rgb(27, 97, 53);
}

.warning {
  color: orange !important;
}

.danger {
  color: red !important;
}

.error {
  color: rgb(255, 146, 146);
}

.closeAlert {
  cursor: pointer;
}

input[type='radio'] {
  width: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.auto-height {
  height: auto !important;
}

.auto-margin {
  margin: auto !important;
}

.padding5 {
  padding: 5px !important;
}

.pad-left10 {
  padding-left: 10px;
}

.pad-s-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pad-s-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pad-s-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pad-v-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pad-v-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pad-v-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pad-v-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.margin-v-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.margin-h-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.no-border {
  border: 0px !important;
}

.bordered {
  border: 1px solid #ebebeb !important;
}

.border-top {
  border-top: 1px solid #ddf2ff !important;
}

.disabled {
  color: rgb(160, 160, 160);
  pointer-events: none;
  cursor: not-allowed !important;
}

.click-disabled {
  color: rgb(160, 160, 160);
  cursor: not-allowed !important;
}

.pull-left {
  float: left !important;
}

.centered {
  margin: auto !important;
  float: none !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;

  label {
    font-size: 14px !important;
  }
}

.separator {
  border: 1px dashed #ebebeb;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

textarea:focus,
input:focus,
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute !important;

  &.open {
    display: block !important;
  }
}

.dropdown-hover-menu {
  padding: 5px;

  &:hover {
    background-color: #0082d6;
    color: #ffffff;

    > ul.sub-menu {
      display: block;

      li {
        padding: 0px;
      }
    }

    > ul.dropdown-menu {
      display: block;
    }
  }
}

.add-action-link {
  margin: 3px 15px;
  padding: 0px 3px;
  cursor: pointer !important;
  text-align: center;
  height: 30px;

  span {
    &.plus {
      font-size: 20px;
      vertical-align: middle;
    }

    &.label {
      padding: 3px 5px;
      font-size: 16px;
      color: #4a90e2;
    }
  }

  &:hover {
    background: #fff;
    border: 1px solid #a9ddff;
    border-radius: 20px;
  }
}

.add-another-link {
  cursor: pointer !important;

  span {
    &.label {
      padding: 3px 5px;
      font-size: 14px;
      color: #4c9ade;
    }
  }
}

.fa {
  padding: 0 2px;
}

// Bootsrap overrides

.panel {
  font-size: 14px;
  background: #fff;
  margin-bottom: 10px;

  .panel-heading {
    padding: 10px;
    font-size: 16px;
    background: #fff;
    border: 1px solid #ebebeb;
    border-bottom: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .panel-body {
    height: 60vh;
    overflow: auto;
    border: 1px solid #ebebeb;
    padding: 15px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    &.fit-height {
      height: 70vh;
    }
  }

  .panel-footer {
    height: 20vh;
  }

  .section {
    border-bottom: 1px solid #ebebeb;
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 15px 0;

    .section-header {
      font-weight: bold;
      border-bottom: 1px solid #ebebeb;
      margin-bottom: 15px;
    }
  }
}

.a10-gui-widgets-panel-body {
  .section {
    //border-bottom: 1px solid #ebebeb;
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 15px 0;

    .section-header {
      font-weight: bold;
      border-bottom: 1px solid #ebebeb;
      margin-bottom: 15px;
    }
  }
}

.form-group {
  .form-control {
    border: 1px solid #acdaf9;
    height: 34px;
    padding: 6px 12px;
    display: block;
    font-size: 14px;
  }

  textarea {
    height: auto !important;
  }
}

.btn {
  border: 0px !important;
  border-radius: 3px !important;
  padding: 6px 12px !important;
  font-size: 14px !important;

  &.full-width {
    width: 100%;
  }

  &.btn-primary {
    background-color: #2d9ae2;
  }

  &.btn-basic {
    background-color: #d9dddf;
  }
}

// Table related stylings

.table {
  // border: 1px solid #EBEBEB;
  font-size: 14px;
  transition: all 400ms ease-in;

  thead {
    tr {
      height: 45px !important;

      th {
        font-weight: 500;
        background: #fff;
        border: 0px;
        padding: 5px;
        font-size: 13px;
        min-height: 42px;
        white-space: initial;
      }
    }
  }

  tbody {
    tr {
      height: auto;

      td {
        padding: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 300;
      }

      &:nth-child(even) {
        background: #fafdff;
      }

      &:nth-child(odd) {
        background: #f1f8ff;
      }

      &:hover {
        background: #eff7ff;
      }

      &.even {
        background: #fafdff !important;
      }

      &.odd {
        background: #f1f8ff !important;
      }
    }
  }

  > tfoot {
    > tr {
      > td {
        background: #fff;
      }
    }
  }

  .row {
    th,
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:nth-last-child(1) {
        text-align: right;
      }
    }
  }

  &:nth-child(even) {
    tbody {
      tr {
        background: #fafdff;
      }
    }
  }

  &:nth-child(odd) {
    tbody {
      tr {
        background: #f1f8ff;
      }
    }
  }

  &:hover {
    > tbody {
      > tr {
        background: #eff7ff;
      }
    }
  }
}

.loadBalancerBox {
  padding: 5px;
  margin-bottom: 5px;
  margin-right: 2px;
  color: white;
  text-align: center;
  float: left;
  width: 100px;
  height: 75px;
  background-color: #00aa6c;
  .cpuThresholdValue {
    font-size: 24px !important;
    font-weight: 500;
  }
  .cpuThresholdTitle {
    font-size: 16px !important;
    font-weight: 300;
  }
}
.loadBalancerBox.green {
  background-color: #00aa6c;
}
.loadBalancerBox.yellow {
  background-color: #f5a623;
}
.loadBalancerBox.red {
  background-color: #ff2e48;
}
.loadBalancerBox.highValue {
  background-color: #2680e8;
}
.loadBalancerBox.lowValue {
  background-color: #2680e8;
  opacity: 0.75;
}
.show {
  visibility: visible !important;
}

.hide {
  visibility: hidden !important;
}

.sliderBackdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2025;
  left: 0;
  top: 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  background: #000;
  transition: opacity 500ms ease-in-out;

  &.fadeIn {
    opacity: 0.2;
  }

  &.fadeOut {
    opacity: 0;
  }
}

.sliderForm {
  float: right;
  z-index: 2056;
  position: fixed;
  right: 0;
  top: 0;
  background: #fbfbfb;
  width: 50%;
  height: 100vh;
  border: 1px solid #ebebeb;
  overflow-y: auto;

  transform: translateX(105%);
  transition: transform 300ms ease-in-out;

  &.slideIn {
    transform: translateX(0%);
  }

  &.slideOut {
    transform: translateX(105%);
  }
}

.sliderHeader {
  padding: 10px 15px;
  font-size: 20px;
  margin-top: 7px;
  margin-bottom: 0px;
  cursor: default;

  .fa-chevron-right {
    font-size: 12px;
    vertical-align: middle;
  }

  .close-btn {
    cursor: pointer;
    font-size: 18px;
    color: #929292;
  }
}

.sliderBody {
  height: 80vh;
  overflow: auto;
}

.formContent {
  max-height: 600px !important;
  min-height: 600px;
  overflow: auto;
  background: white;
  border-radius: 4px;
  margin-top: 5px;
}

.sliderFooter {
  height: 10vh;
  padding: 10px 0;
}

.pageIndex {
  padding: 2px;
  font-weight: bold;
  color: #858b90;
  background: #efeae4;
  border: 1px #736f6b;
  border-radius: 3px;
  box-shadow: 1px 2px;
  margin-left: 3px;
  cursor: pointer;
}

.pageIndexSelected {
  padding: 2px;
  font-weight: bold;
  color: #d0d8df;
  background: #3e5065;
  border: 1px #e8e4e0;
  border-radius: 3px;
  box-shadow: 1px 2px;
  margin-left: 3px;
  cursor: pointer;
}

.infoHeader {
  margin: 0px 0 30px 0;

  span {
    font-size: 20px;
    float: left;
    font-weight: bolder;
  }
}

.searchBox {
  float: right;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 14px;
}

.dropdown-menu {
  top: unset !important;
  right: 0px !important;
  left: unset !important;
  margin-top: 35px;
  font-size: 14px;
  cursor: pointer;
  padding: 2px;
  border: none;
  box-shadow: 3px 3px rgba(102, 102, 102, 0.1);

  li {
    padding: 5px;

    &:hover {
      background-color: #d1e7fd;
      border-radius: 2px;
      border: solid 1px #afd7ff;
    }
  }
}

.sectionContainer {
  padding-top: 26px;
  min-height: 75vh;
}

.componentContainer {
  background: #fafafa;
  padding: 5px 15px 3px 15px;
}

// first header/container should not have top/bottom padding
.subHeader + .componentContainer {
  padding: 26px 15px;
}

.subHeader {
  box-shadow: 0 1px 1px 1px rgba(228, 228, 228, 0.5);
  background-color: #f8f7f7;
  > .inline > .auto-margin {
    display: flex;
  }
}

.end-of-sale-info {
  line-height: 4;
  position: absolute;
  float: right;
  right: 200px;
}

.collapsible {
  overflow-y: hidden;
  max-height: 500px;

  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  &.slideUp {
    max-height: 0px;
  }

  &.slideDown {
    max-height: 500px;
  }

  .table {
    width: 99.8%;
  }
}

.pageIndexer {
  float: right;
  margin-right: 30px;
}

@media (max-width: 500px) {
  .sliderForm {
    width: 70%;
  }
}

.appLauncher {
  text-align: right;
  color: #337ab7;
}

.blue-text {
  color: #0093f3;
}

tr:hover > td > .actions {
  display: block;
}

.actions {
  display: none;
  float: right;
  color: #5d5d5d;
  position: absolute;
  right: 25px;

  .fa {
    cursor: pointer;
    font-size: 20px;
    margin-left: 5px;
    background-size: 18px;
    background-position: 50%;
    background-repeat: no-repeat;
    width: 25px !important;
    height: 25px !important;
    vertical-align: top;
    color: #969696;

    &:hover {
      color: #000;
      background-color: #fff;
      border: 1px solid #ebebeb;
      border-radius: 5px;
    }
  }

  .fa-ellipsis-v {
    width: 14px;
    text-align: right;
    float: right;
    margin-top: 2px;
    padding: 2px;
  }

  .btn-group {
    vertical-align: top;
  }
}

.control-switch {
  input[type='checkbox']:not(:checked),
  input[type='checkbox']:checked {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  label {
    position: relative;
    display: inline-block;
    font: 75% sans-serif;
    text-shadow: 0 1px 1px white;
  }

  input[type='checkbox'].switch + div {
    width: 40px;
    height: 20px;
    cursor: pointer;
    border-radius: 999px;
    margin: 0 5px -2px;
    background: rgba(48, 55, 68, 0.25);
    background-origin: border-box;
    background-clip: border-box;
    overflow: hidden;
  }

  input[type='checkbox'].switch:checked + div {
    padding-left: 20px;
    background: #55af32;
    width: 40px;
  }

  input[type='checkbox'].switch + div:before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-radius: inherit;
    background: #ffffff;
    margin: 1px;
  }

  input[type='checkbox'].switch:active + div:before {
    background-color: #aaa;
  }

  &.sw-small {
    input[type='checkbox'].switch + div {
      width: 25px;
      height: 14px;
    }

    input[type='checkbox'].switch:checked + div {
      width: 24px;
      padding-left: 10px;
    }

    input[type='checkbox'].switch + div:before {
      width: 12px;
      height: 12px;
    }
  }
}

.a10-icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  background-size: 24px;
  background-repeat: no-repeat;
  position: relative;
  background-position: 0;
  margin: 0px 7px -5px 5px;
}

.edit-icon {
  background-image: url(../assets/images/svg/icon-edit.svg);
}

.view-icon {
  background-image: url(../assets/images/svg/actions/view.svg);
}

.delete-icon {
  background-image: url(../assets/images/svg/actions/delete.svg);
}

.support-icon {
  background-image: url(../assets/images/svg/actions/support.svg);
}

.metric-icon {
  background-image: url(../assets/images/svg/hc-icons/metric.svg);
}

.alert-icon {
  background-image: url(../assets/images/svg/hc-icons/Alert.svg);
}

.thunder-icon {
  background-image: url(../assets/images/svg/adc-icon.svg);
}

.adc-icon,
.slb-icon {
  background-image: url(../assets/images/svg/adc-icon.svg);
}

.lightning-icon {
  background-image: url(../assets/images/svg/lightning-adc-icon.svg);
}

.cgn-icon {
  background-image: url(../assets/images/svg/cgn-icon.svg);
}

.gifw-icon {
  background-image: url(../assets/images/svg/gi-firewall-icon.svg);
}

.gtp-icon,
.gtp-fw-icon {
  background-image: url(../assets/images/svg/gtp-icon.svg);
}

.ssli-icon {
  background-image: url(../assets/images/svg/ss-li-icon.svg);
}

.allColumn {
  column-span: all;
}

.tile-view-off {
  background-image: url(../assets/images/svg/icon-tile-view-off.svg);
}

.tile-view-on {
  background-image: url(../assets/images/svg/icon-tile-view-on.svg);
}

.list-view-off {
  background-image: url(../assets/images/svg/icon-list-view-off.svg);
}

.list-view-on {
  background-image: url(../assets/images/svg/icon-list-view-on.svg);
}

.add-another {
  background-image: url(../assets/images/svg/actions/add-another.svg);
}

.remove {
  background-image: url(../assets/images/svg/actions/delete-another.svg);
}

.user-profile {
  background-image: url(../assets/images/svg/actions/profile.svg);
}
.reports-icon {
  background-image: url(../assets/images/svg/report.svg);
}
.scheduled-reports-icon {
  background-image: url(../assets/images/svg/reports-schedule.svg);
}
.text {
  color: #000 !important;
  text-decoration: none !important;

  &:hover {
    background: transparent;
  }
}

.tag {
  font-size: 14px !important;
  padding: 0px 7px;
  color: #fff;
  background: #929292;
  min-width: 22px;
  display: inline-block;
  height: 22px;
  border-radius: 12px;
  margin: 8px 0 0 5px;
}

.pdl-5 {
  padding-left: 5px !important;
}

.pdr-5 {
  padding-right: 5px !important;
}

.pdlr-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pad-5 {
  padding: 5px !important;
}

.marginl-5 {
  margin-left: 5px !important;
}

.marginr-5 {
  margin-right: 5px !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .ant-form-item-control {
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.td-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.td-truncate-min {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 150px;
  max-width: 250px;
}

.page-header {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  float: left;
}

.panel-Label {
  font-size: 20px;
  margin-left: 10px;
  // font-weight: bolder;
}

.background-polygons {
  background: url('../assets/images/cloud-polygon-background-polygons.svg')
      no-repeat,
    linear-gradient(122deg, #61a7d3, #0078c3) !important;
  background-position-x: 100% !important;
  background-position-y: 100% !important;
}

.hs-text {
  vertical-align: middle;
  margin-right: 5px;
}

.hs-hd-text {
  vertical-align: -webkit-baseline-middle;
  margin-right: 5px;
}

li {
  a {
    display: block;
  }
}

.version-dropdown {
  margin: 0px !important;
  .ant-popover-inner-content {
    padding: 5px 10px;
  }

  ul.dropdown-list {
    display: block;
    top: 0px;
    left: 775px !important;
    padding: 5px;
    max-height: 140px;
    overflow: auto;
    margin-bottom: 0px !important;
    li {
      height: 40px;
      padding: 10px;

      &:hover {
        background: #e7f6ff;
      }
    }

    &:before {
      content: none;
    }
  }

  &:hover {
    ul.dropdown-list {
      display: block;
    }
  }
}

.server-container--3aCeF,
.service-object-container--2-lB3 {
  padding: 26px 15px;

  .ant-row-flex-middle {
    -ms-flex-align: normal;
    align-items: normal;

    .infoHeader span {
      font-size: 26px;
      margin-left: 8px;

      & + .roundNumber {
        margin: 5px 0 0 5px;
      }
    }

    .ant-col-lg-16,
    .ant-col-lg-12 {
      .table-header {
        margin-bottom: 12px;

        button.action-button {
          height: 32px;
        }
      }
    }

    & + .service-object-list-container--3dGjM {
      padding-top: 0px;
    }
  }

  .server-list-container--3WgrZ {
    padding-top: 0px;
  }
}

// modal button margins
// .ant-modal-footer button + button {
// margin-left: 15px;
// margin-right: 0px;
// }

.task-manager-container {
  .title {
    font-size: 26px;

    & + .count-icon {
      .status-icon.status-icon-32 {
        width: 22px !important;
        height: 22px !important;
        background: #5d5959;
      }
    }
  }
}

// .ant-table-tbody > tr > td {
//   padding: 9px 7px 10px !important;
// }

.a10-sliding-page-content--h2232 {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
    top: 42.1% !important;
  }

  .ant-table-tbody {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
      top: 50% !important;
    }
  }
}

#root
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div.task-manager-container
  > div.main-panel
  > div
  > div
  > div
  > div
  > div
  > div
  > table
  > tbody
  > tr.ant-table-expanded-row.ant-table-expanded-row-level-1
  > td:nth-child(2)
  > div
  > div
  > div
  > div.ant-collapse-content.ant-collapse-content-active
  > div
  > div
  > svg
  > g.highcharts-elementGroup
  > image {
  display: none;

  & + text {
    display: none;
  }
}

#root
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div.row.no-margin.sectionContainer.appServices
  > div
  > div.ant-table-wrapper
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr
  > th:nth-child(7)
  > div
  > div
  > button {
  height: 20px;
}

.row.app-list {
  margin-left: auto;
  margin-right: auto;

  .col-md-5.inline {
    left: 60px;

    // installed apps, missing the vertical bar like my apps page is
    .searchbar-container {
      float: right;
      display: block;
      flex: 0 0 66.666667%;
      padding-left: 10px;
      width: 100%;

      & + span.vertical-split-divider + div.col-md-4 {
        //
        margin-left: 2px;
      }
    }
  }
}

.ant-row.stats-bar--1crJX {
  bottom: 10px;

  .stats-col--3vyTy {
    height: 100%;
    top: 50%;
    transform: translateY(-50%);

    div:first-child {
      margin-top: 10px;
    }
  }
}

.user-selector {
  span.ant-avatar i.anticon-user {
    position: relative;
    bottom: 3px;

    svg {
      font-size: 22px;
    }
  }
}

// A10Model Delete Btn

.nobtn {
  width: 92px;
  height: 40px !important;
  margin-left: 30px !important;
}

.yesbtn {
  width: 92px;
  height: 40px !important;
  background-color: #fcdddd !important;
  color: #ff2e48 !important;
  border-color: #fcdddd !important;
  margin-left: 0px !important;
}

.yesbtn:hover {
  background-color: #ffc7c7 !important;
  border-color: #e9c7c7 !important;
}

.ant-modal-footer {
  justify-content: flex-start;
}

//  sub logs for max / avg

.sub-header-log {
  border-bottom: 1px solid #fff !important;
}

.sub-header-log-list {
  border-top: 1px solid #fff !important;
  font-weight: 300 !important;
}

// Apps title

#appsTitle {
  font-size: 24px;
  margin-top: -3px !important;
}

// close btn

.clsebtnFmtslider {
  float: right;
  text-align: right;
  cursor: pointer;
}

// desktop icon

.desktopIcon {
  font-size: 48px;
}

.desktopIcon svg {
  width: 2.4em;
  height: 2.4em;
}

.sliding-panel-icon {
  img {
    width: 30px !important;
    height: 30px !important;
  }
}

// responsiveness for header

@media (max-width: 991px) and (min-width: 878px) {
  .ant-col-md-9 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 34%;
  }

  .ant-col-md-11 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 27%;
  }

  .ant-col-md-10 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 60%;
  }

  .ant-col-md-12 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 36%;
    float: right;
  }

  .menu-item {
    width: 75px !important;
    font-size: 10px !important;
  }

  .logo {
    background-size: 66px !important;
  }

  body {
    line-height: 1.8 !important;
  }
}

@media (max-width: 877px) and (min-width: 778px) {
  .ant-col-md-9 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 34%;
  }

  .ant-col-md-11 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 27%;
  }

  .ant-col-md-10 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 60%;
  }

  .ant-col-md-12 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 36%;
    float: right;
  }

  .menu-item {
    width: 66px !important;
    font-size: 9px !important;
  }

  .logo {
    background-size: 66px !important;
  }

  .menu-container .provider-tenant-menu {
    width: 138px;
  }

  .ant-avatar-lg {
    width: 32px !important;
    height: 32px !important;
    line-height: 29px !important;
  }

  .user-selector span.ant-avatar i.anticon-user svg {
    font-size: 18px !important;
  }

  .help-container {
    padding-left: 0px !important;
    padding-right: 5px !important;
    cursor: pointer;
  }

  .help-container .help {
    width: 16px !important;
    height: 15px !important;
    font-size: 12px !important;
  }

  body {
    line-height: 2 !important;
  }
}

@media (max-width: 777px) and (min-width: 677px) {
  .ant-col-md-9 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 34%;
  }

  .ant-col-md-11 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 27%;
  }

  .ant-col-md-10 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 60%;
  }

  .ant-col-md-12 {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 36%;
    float: right;
  }

  .menu-item {
    width: 56px !important;
    font-size: 7px !important;
  }

  .logo {
    background-size: 52px !important;
    margin-top: 11px !important;
  }

  .menu-container .provider-tenant-menu {
    width: 138px;
    margin-right: -9px;
  }

  .ant-avatar-lg {
    width: 32px !important;
    height: 32px !important;
    line-height: 29px !important;
  }

  .user-selector span.ant-avatar i.anticon-user svg {
    font-size: 18px !important;
  }

  .help-container {
    padding-left: 0px !important;
    padding-right: 5px !important;
    cursor: pointer;
  }

  .help-container .help {
    width: 16px !important;
    height: 15px !important;
    font-size: 12px !important;
  }

  body {
    line-height: 2.7 !important;
  }

  .nav-selector--adfmg .current-block--99UA5 .current-icon--2rmEs {
    left: -3px !important;
    width: 27px !important;
    height: 27px !important;
  }

  .nav-selector--adfmg .current-block--99UA5 .current-text--1bNKj {
    margin-left: -20px;
  }
}

.toolTipWidth.ant-tooltip {
  min-width: 250px;
  max-width: unset;
  // .ant-tooltip-inner {
  //   width: 378px;
  // }
}

.toolTipFixed.ant-tooltip {
  min-width: 250px;
  max-width: unset;
  position: fixed;
}

.tableOverToolTip {
  max-width: unset;

  th,
  td {
    padding: 5px;
    text-align: left;
  }
}
.tableOverToolTip.scrollTable {
  max-height: 250px;
  overflow-y: auto;
}
.tableOverToolTip.maxWidth {
  width: 400px;
}
.mgntInFt {
  font-size: 14px;
}

.mgntInSwitch {
  margin-left: 2px !important;
}
.mandatoryField::after {
  content: '';
  width: 3.5px;
  height: 3.5px;
  border-radius: 100px;
  background-color: #e57373;
  display: inline-block;
  vertical-align: text-top;
  margin-left: 5px;
}

.width-40P {
  width: 40% !important;
}

.pointer {
  cursor: pointer;
}

.table-row-padding {
  padding: 10px 10px 20px 0;
}

.admin-link {
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: #665aa8;
  text-align: center;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.border-bottom-none {
  .ant-collapse-item {
    border-bottom: none;
  }
}

.ant-calendar-picker-icon svg {
  vertical-align: inherit;
}

.editPanel {
  padding: 30px 50px;
}

.enforcedDate {
  display: flex;
  margin-left: 90px;
  .enforcedText {
    margin-left: 40px;
    margin-top: 8px;
  }
}

.datePicker {
  width: 296px;
  margin: 0px 0px 25px 0px;
}

.userAttrHeading{
  line-height: initial;
  width: 70%;
  white-space: break-spaces;
}

.ant-form-item{
  margin-bottom: 16px;
}