// .appserviceslist {
//   table {
//     th {
//       &:nth-child(2) {
//         width: 2%;
//       }
//     }
//   }
// }

.vportList.ant-table-wrapper {
  margin: 0px !important;
}

// .appLauncherDiv {
//   text-align: right;
//   color: #337ab7;
//   font-size: 16px;
// }

.marginBottom15 {
  margin-bottom: 15px;
}

.sectionContainer {
  .anticon-right.arrow {
    margin-top: 0px !important;
  }
  .infoHeader {
    margin: 10px 0 0px 0;
  }

  .appServicesTitle {
    margin-left: -15px;
    margin-right: 15px;
    margin-top: 8px;
  }

  .appLauncherDiv {
    margin-top: 8px;
  }

  .roundNumber {
    margin: 5px 5px;
  }

  .Rectangle-10 {
    width: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 16px !important;
    padding: 15px 0;
    line-height: 0;
    background: #38a9e4;
    color: white;
  }

  .searchBox {
    margin: 5px 0;
  }

  // .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow{
  //   top: 8px;
  // }
}

// .ant-popover-hidden{
//   display:block !important;
// }
.thunderAppPopoverCls {
  .ant-popover-content {
    .ant-popover-arrow {
      background-color: #616161 !important;
      width: 20px !important;
      height: 20px !important;
      left: 0px !important;
    }

    .ant-popover-inner {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #f4f4f4 !important;
      background-color: #616161 !important;

      .ant-popover-inner-content {
        p {
          margin-bottom: 0.5em;
          color: white !important;
        }
      }
    }
  }
}
.edit-lp-link {
  &:hover {
    text-decoration: none;
  }
  color: #525151 !important;
  text-decoration: 'none' !important;
}

.active-app-switch {
  .vertical-split-divider {
    border-right: 1px dashed #d2d2d2;
    padding-right: 20px;
  }

  .pad-right-10 {
    padding-right: 10px;
  }
}

.warning-app-svc {
  color: orange !important;
}

.danger-app-svc {
  color: red !important;
}
