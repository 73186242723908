.labelIcon {
  width: 23px;
  height: 23px;
  margin-right: 14px;
}
.enabled {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #9b9b9b;
  margin-left: 5px;
}
.uploadIcon {
  width: 23px;
  height: 18px;
}
