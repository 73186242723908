.left-pad {
  padding-left: 20px;
}

.divider-line {
  border: solid 1px #f2f2f2;
  margin-bottom: 20px;
}

.total-cap-text {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;

  .total-cap-val {
    margin-left: 10px;
    padding: 0 10px;
    height: 18px;
    border-radius: 100px;
    background-color: #90a4ae;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
}

.input-box {
  border: 1px solid #afd7ff;
  padding: 0px 11px;
  border-radius: 5px;

  .input-box-content {
    margin: 0 0 0 5px;
    display: inline-block;
    vertical-align: middle;
  }
}

.ant-form-item {
  margin-bottom: 12px;
}

.provider-panel {
  padding: 30px 30px 8px;
}

.provider-label {
  font-size: 14px;
  font-family: 'Segoe UI';
  font-weight: normal;
  color: #000;
}