.list-detail-container {
  .workflow-details-container {
    padding: 0 20px;
  }
  .tasks-status-container {
    padding: 0 20px 20px;
    .tasks-status-header {
      padding: 20px 0 10px;
      .tasks-status-title {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
