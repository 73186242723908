.activation-link {
  word-wrap: break-word;
}

.helpInfo {
  position: absolute;
  float: right;
  margin-left: 23em;
  margin-top: -0.6em;
}

.scrollLimitedHeight {
  max-height: 210px;
  overflow-y: auto;
  overflow-x: hidden;

  .scrollMinHeight {
    min-height: 170px;
  }
}

.svgInc {
  svg {
      font-size: 19px;
  }
}

.pdLt-16 {
  padding-left: 16px !important;
}

.pdrgt-26 {
  padding-right: 26px !important;
  margin-left: -12px !important;
}

.pdlr-24 {
  padding-left: 24px !important;
}

.addTenantTitle {
  display: inline-block;
  font-size: 16px;
  color: #4a90e2;
  padding-left: 12px;
}

.resetBtn {
  text-align: center;
  width: 240px;
  height: 46px;
  border-radius: 4px;
  border: solid 1px #d2d2d2;
  background-color: #ffffff;
}
.yesButton,.cancelButton{
  width: 92px;
  height: 40px !important;
  margin-left: 10px;
 }