.cm-s-a10.CodeMirror {
  background: #ffffff;
  color: #202020;
}

.cm-s-a10 div.CodeMirror-selected {
  background: #e0e0e0;
}

.cm-s-a10 .CodeMirror-line::selection,
.cm-s-a10 .CodeMirror-line > span::selection,
.cm-s-a10 .CodeMirror-line > span > span::selection {
  background: #e0e0e0;
}

.cm-s-a10 .CodeMirror-line::-moz-selection,
.cm-s-a10 .CodeMirror-line > span::-moz-selection,
.cm-s-a10 .CodeMirror-line > span > span::-moz-selection {
  background: #e0e0e0;
}

.cm-s-a10 .CodeMirror-gutters {
  background: #f5f5f5;
  border-right: 0px;
  z-index: 0;
}

.cm-s-a10 .CodeMirror-guttermarker {
  color: #ac4142;
}

.cm-s-a10 .CodeMirror-guttermarker-subtle {
  color: #b0b0b0;
}

.cm-s-a10 .CodeMirror-linenumber {
  color: #b0b0b0;
}

.cm-s-a10 .CodeMirror-cursor {
  border-left: 1px solid #505050;
}

.cm-s-a10 span.cm-comment {
  color: #a6a6a6;
}

.cm-s-a10 span.cm-atom {
  color: #aa759f;
}

.cm-s-a10 span.cm-number {
  color: #aa759f;
}

.cm-s-a10 span.cm-property,
.cm-s-a10 span.cm-attribute {
  color: #90a959;
}

.cm-s-a10 span.cm-keyword {
  color: #4a90e2;
}

.cm-s-a10 span.cm-event {
  color: #00aa6c;
}

.cm-s-a10 span.cm-namespace {
  color: #00aa6c;
}

.cm-s-a10 span.cm-operator {
  color: #0000ff;
}

.cm-s-a10 span.cm-controller {
  color: #9013fe;
}

.cm-s-a10 span.cm-builtin {
  color: #a80b19;
}

.cm-s-a10 span.cm-string {
  color: #f4bf75;
}

.cm-s-a10 span.cm-variable {
  color: #90a959;
}

.cm-s-a10 span.cm-variable-2 {
  color: #6a9fb5;
}

.cm-s-a10 span.cm-def {
  color: #d28445;
}

.cm-s-a10 span.cm-bracket {
  color: #202020;
}

.cm-s-a10 span.cm-tag {
  color: #ac4142;
}

.cm-s-a10 span.cm-link {
  color: #aa759f;
}

.cm-s-a10 span.cm-error {
  background: #ac4142;
  color: #505050;
}

.cm-s-a10 .CodeMirror-activeline-background {
  background: #dddcdc;
}

.cm-s-a10 .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

.cm-s-a10 .CodeMirror-vscrollbar {
  position: absolute;
  z-index: 1;
}

.cm-s-a10 .CodeMirror-hscrollbar {
  position: absolute;
  z-index: 1;
}

.cm-s-a10 pre.CodeMirror-line {
  z-index: 0 !important;
}