.aws-vm-template {
  border-top: 1px solid #f2f2f2;
  margin: 0 -30px;
  padding: 15px 0;

  span.title {
    font-size: 16px;
    font-weight: 500;
    color: #363636;
    padding-left: 20px;
  }
}

.aws-image-div {
  height: 114px;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 2px 2px 0 0 rgba(217, 226, 237, 0.5);
  border: solid 1px #c5d3da;
  background-color: #ffffff;
  padding: 0 10px;

  .aws-image-list {
    display: list-item;
    padding: 5px 0px;
  }
}

.aws-network-dropdown {
  font-size: 12px;
  width: 126px !important;

  .ant-select-focused {
    font-size: 12px;
    width: 126px !important;
  }

  .ant-select-open {
    font-size: 12px;
    width: 126px !important;
  }

  .ant-select-selection--single {
    height: 30px;
    border: solid 1px #afd7ff;
  }

  .ant-select-dropdown-menu-item-selected {
    height: 30px;
  }

  .ant-select-selection__rendered {
    margin-top: 3px;
  }

  .ant-select-selection__placeholder {
    margin-top: -10px !important;
  }

  .ant-select {
    height: 30px;
  }
}

.aws-network-dropdown.ant-select.ant-select-disabled {
  .ant-select-selection {
    cursor: not-allowed;
    opacity: 1;
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.addEth2Title {
  display: inline-block;
  font-size: 16px;
  color: #4a90e2;
  padding-left: 12px;
}