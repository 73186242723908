.main {
  position: relative;
}

.clsBtn {
  float: right;
}

.popoverinfo {
  width: 30% !important;
  max-width: 270px;
}

.editBtn {
  padding-top: 10px;
}

.updateBtn {
  margin-right: 12px;
}

.borderBotttomTitle {
  border-bottom: 1px dashed #4a90e2;
  opacity: 0;
}

.borderBotttomTitle:hover {
  border-bottom: 1px dashed #4a90e2;
  opacity: 1;
}
.custom-tooltip-head {
  position: absolute;
  left: 91px;
}
.custom-tooltip-input {
  position: absolute;
  right: 0;
  left: 0;
}
.custom-tooltip-input-indent {
  position: absolute;
  right: 0;
  left: 85px !important;
}
.custom-tooltip-head-indent {
  position: absolute;
  left: 175px !important;
}
.custom-tooltip-device-info {
  position: relative;
  left: -98px;
}
