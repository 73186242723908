.sip-vm-template {
  margin: 0 -20px;
  padding: 15px 0;

  span.title {
    font-size: 14px;
    font-weight: 300;
    color: #363636;
    padding-left: 20px;
  }
}

.sip-network-dropdown {
  font-size: 12px;
  width: 160px !important;

  .ant-select-focused {
    font-size: 12px;
    width: 126px !important;
  }

  .ant-select-open {
    font-size: 12px;
    width: 126px !important;
  }

  .ant-select-selection--single {
    height: 24px;
    border: solid 1px #afd7ff;
  }

  .ant-select-dropdown-menu-item-selected {
    height: 30px;
  }

  .ant-select-selection__rendered {
    margin-top: 0px;
  }

  .ant-select-selection__placeholder {
    margin-top: -10px !important;
  }

  .ant-select {
    height: 30px;
  }
}

.sip-network-dropdown.ant-select.ant-select-disabled {
  .ant-select-selection {
    cursor: not-allowed;
    opacity: 1;
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.ant-select-dropdown.sip-network-dropdown {
  overflow-y: auto;
  height: fit-content;
  max-height: 100px;
}

.secondaryIPTable {
  thead > tr {
    th:nth-child(1) {
      width: 40%;
    }
    th:nth-child(2) {
      width: 40%;
    }
    th:nth-child(3) {
      width: 20%;
    }
  }

  td {
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    width: '40%';
  }
  tr {
    min-height: 36px;
    max-height: max-content !important;
    height: 36px;
  }
  .ant-table-body {
    overflow-y: hidden;
  }

  .ant-form-item-control {
    line-height: 28px;
  }
}

.interface-name {
  color: black;
}

.scip-icon {
  padding: 3px !important;
  height: 25px !important;
  margin: 0px !important;
}

.scip-input {
  height: 24px !important;
  width: 160px !important;
}

.scip-tooltip {
  .ant-tooltip-inner {
    position: relative;
    bottom: -18px;
  }
}

.scip-compartment {
  color: #4a90e2;
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px !important;
  text-align: right;
  line-height: normal;
  padding-left: 0%;
  width: 160px;

  .a10-widget-appicon svg {
    font-size: 12px;
    stroke: #4a90e2 !important;
    stroke-width: 2;
  }
}

.scip-table-row-alignment {
  vertical-align: top;
}

.oci-publicip-compartment-dropdown {
  .ant-select {
    width: 152px !important;
  }
  .ant-select-focused {
    font-size: 14px !important;
    width: 152px !important;
  }

  .ant-select-open {
    font-size: 14px !important;
    width: 152px !important;
  }
}
