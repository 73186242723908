.tab {
  display: flex;
  align-items: center;
  .ant-badge-count {
    background-color: #c3cbcf;
    margin-left: 5px;
  }
}
.tab-information {
  margin-top: 2em;
  padding-left: 0;
}
.license-information {
  border-radius: 4px;
  border: 1px solid #cfd9e2;

  .title {
    font-weight: 600;
  }
}
.actions {
  display: none;
  float: right;
  color: #5d5d5d;
  position: absolute;
  right: 25px;
  top: 5px;
}

tr:hover > td > .actions {
  display: block;
}

.device-name {
  display: inline-block;
  padding-left: 5px;
}

.top-7 {
  top: -7px;
}

.modal-on-form {
  z-index: 9999;
}

.lic-detail-row {
  margin-left: 5px;
  margin-right: 0px;

  .lic-detail-title {
    font-weight: 500;
    width: 25%;
    min-width: 25%;
  }

  .lic-detail-value {
    white-space: nowrap;
    overflow: hidden;
    padding-right: 20px;
    white-space: initial;
    overflow-wrap: break-word;
  }

  .lic-warning {
    color: orange !important;
  }

  .lic-danger {
    color: red !important;
  }

  .line-2-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
