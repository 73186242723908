.appdetails {
  float: right;
}

.formItem {
  line-height: 36px;
  margin-bottom: 1px !important;
}

.form-value{
  color: #1b1b1b;
  font-size: 14px;
}
.form-row{
  line-height: 30px;
}
.formScroll {
  padding-left: 35px;
  font-weight: 400;
  padding-top: 15px;
  padding-bottom: 15px;
}

.headerAboutappInfo {
  height: 60px;
  line-height: 60px;
  border-bottom: solid 1px #f2f2f2;
  padding: 0 14px;
}
.about-panel-container{
  div[class^="a10-widgets-panel--"]{
    border: 1px solid #e7ecf1;
  }  
}

.about-list {
  list-style-type: square;
  padding-left: 15px;
}