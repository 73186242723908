#searchBar {
  float: right;
}

#iconVM {
  img {
    width: 26px !important;
    height: 26px !important;
  }
}

.verticalDivider {
  margin: 0px 18px !important;
}

.dataCenter {
  font-size: 26px;
  font-weight: normal;
  color: #363636;
}

.bottomBorderRem {
  .ant-collapse-item {
    border-bottom: none;
  }
}

.borderLeftData {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 500;
}

.networkDropdown {
  font-size: 12px;

  .ant-select-focused {
    font-size: 12px;
  }

  .ant-select-open {
    font-size: 12px;
  }

  .ant-select-selection--single {
    height: 30px;
    border: solid 1px #afd7ff;
  }

  .ant-select-dropdown-menu-item-selected {
    height: 30px;
  }

  .ant-select-selection__rendered {
    margin-top: 4px;
  }

  .ant-select {
    height: 30px;
  }
}

.radioBtn {
  td {
    max-width: 10px !important;
  }
}

.destinationIcon {
  margin-left: 2px !important;

  img {
    width: 42px !important;
    height: 26px !important;
  }
}

.searchBarVMCluster {
  position: relative;
  z-index: 99999999;
  top: 14px;
}

.panelBodyPadding {
  padding: 30px 30px !important;
}

.custom-tooltip-storage {
  position: absolute;
  right: 0;
  left: 49px;
}