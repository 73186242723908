.modal-section {
  padding: 5px;
  min-height: 50vh;

  .help-col {
    text-align: center;
    cursor: pointer;
    .help-icons {
      padding: 30px;
      background-color: #f3f9ff;
      border: 1px solid #e6f3ff;
      border-radius: 3px;
    }
  }
}
.help-panel-container {
  div[class^='a10-widgets-panel--'] {
    border: 1px solid #e7ecf1;
  }
}
.help-support-links {
  padding: 30px 0px;
  .help-col {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .help-icon-container {
    margin: 0 auto;
    width: 100px;
  }
  .help-icon-box {
    width: 100px;
    height: 100px;
    background-color: #f3f9ff;
    border: 1px solid #e6f3ff;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .help-icon-text {
    margin: 0 auto;
    width: fit-content;
    text-align: center;
  }
  .help-icons {
    background-repeat: no-repeat;
    width: 35%;
    height: 40%;
    display: inline-block;
    background-size: 31px;
  }
  .product-document {
    background-image: url(../images/product-document.svg);
  }
  .product-demo {
    background-image: url(../images/product-demo.svg);
  }
  .technical-articles {
    background-image: url(../images/technical-articles.svg);
  }
  .video-tutorials {
    background-image: url(../images/video-tutorial.svg);
  }
  .white-papers {
    background-image: url(../images/white-paper.svg);
  }
  .create-ticket {
    background-image: url(../images/create-ticket.svg);
  }
  .chat-support {
    background-image: url(../images/chat-support.svg);
  }
  .privacy-policy {
    background-image: url(../images/privacy-policy.svg);
  }
  .end-of-sale {
    background-image: url(../images/end-of-sale.svg);
  }
  .getting-started {
    background-image: url(../images/getting-started.svg);
  }
}

.help-support-entry {
  margin-left: 20px;
  .a10-widget-appicon {
    cursor: pointer;
    svg {
      font-size: 24px;
    }
  }
}
