.border-bottom-cls {
  border-bottom: 1px solid #e7e7e7;
}

.bulk-action {
  width: 79px;
  height: 19px;
  font-size: 16px;
  font-weight: normal;
  color: #c4c4c4;
}

.bulkSwitch {
  margin-left: 10px;
}

.bulkdelete {
  opacity: 0.28;
  margin-left: 12px;
}

.global-object-explorer {
  .goe-loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.tenantUl:hover {
  text-decoration: underline #36363675;
  cursor: pointer;
}

.add-user {
  color: #4a90e2;
}

.search-input {
  margin-right: 6px;
}
