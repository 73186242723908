.apps {
  .app-list {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    min-height: 300px;

    .info {
      padding: 15px;
    }

    .app {
      background-color: #f3f9ff;
      padding: 25px 0 25px 25px;
      border-radius: 4px;
      height: 190px;
      margin: 14px 10px;

      .details-section {
        color: #a9a9a9;
        font-size: 12px;

        .title {
          color: #000;
          font-size: 18px;

          .version {
            color: #a9a9a9;
            font-size: 12px;
            margin-left: 3px;
          }
        }

        .desc {
          font-size: 14px;
          margin: 10px 0px;
          color: #9b9b9b;
          font-weight: 300;
          height: 90px;
          overflow-y: auto;
          word-wrap: break-word;
        }
      }

      .actions {
        z-index: 999;
        right: 10px;
        top: 5px;

        .fa-ellipsis-v {
          padding: 2px 5px;
        }
      }

      .dropdown-menu {
        right: 15px !important;
        top: 30px !important;
        padding: 0px;
      }

      &:hover {
        background-color: #e6f3ff;

        .actions {
          display: block;
        }
      }
    }
  }

  .app-content-icon {
    height: 60px;
    width: 60px;
    background-size: 50px;
    background-repeat: no-repeat;
    margin-top: 15px;
    display: inline-block;
  }
}

.icons-wrap {
  position: relative;
  text-align: center;

  .show-dirty-list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-180%, -50%);
    margin-right: 5px;
  }
}

.devices-tab {
  .ant-tabs-tab {
    font-size: 16px;
  }
}

.margintop10 {
  margin-top: 10px;
}
.marginBottom10 {
  margin-bottom: 10px;
}

.margintop75 {
  margin-top: 75px;
}

.sidepadding0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.width180 {
  width: 180px;
}

.width200 {
  width: 200px;
}

.width250 {
  width: 250px;
}

.width260 {
  width: 260px;
}

.grid {
  display: grid;
}

.healthStatus {
  margin-left: 10px;
}

.fontWeight300 {
  font-weight: 300;
}

#pdgBtmPrt {
  padding-bottom: 28px;
  .ant-collapse-content {
    width: 100%;
  }
}

.hrDivider {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin: 0 -12px;
}

.global-object-explorer {
  .goe-loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.reboot-device-labels {
  label {
    span {
      font-size: 12px !important;
    }
  }

  .default-boot-image {
    span {
      font-weight: 500;
    }
  }
  .non-default-boot-image {
    span {
      font-weight: 200;
    }
  }
}
