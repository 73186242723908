.showAlertLogText {
  font-size: 15px;
  font-weight: 450;
  padding: 5%;
  color: #4a90e2;
}

#countTag {
  margin-top: 4px;
}

.add-action {
  svg[data-icon='desktop'] {
    font-size: 28px;
  }
}
