.authentication {
  padding: 0 40px;
  min-width: 800px;

  & > div {
    width: 100%;
    position: relative;
  }

  .action-container {
    position: absolute;
    top: 15px;
    right: 20px;
  }

  .auth-content {
    padding: 0 20px;
    margin: -10px -45px;

    .auth-panel {
      &-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 65px;
        border-top: solid 1px rgba(217, 226, 237, 0.5);
        border-bottom: solid 1px rgba(217, 226, 237, 0.5);

        &:first-child {
          border-top: none;
        }
        &:last-child {
          border-bottom: none;
        }
      }

      &-col {
        flex: auto 0 1;
        margin-right: 60px;
        display: flex;
        flex-flow: row wrap;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.long-string {
          flex-shrink: 4;
        }
      }

      &-label {
        flex: auto 0 0;
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        margin-right: 40px;
        white-space: nowrap;
      }

      &-value {
        flex: auto 1 1;
        color: #000000;
        font-size: 14px;
        font-weight: 300;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: bottom;
      }
    }
  }
}

.ant-descriptions-item-label {
  font-weight: bold;
}

.auth-card {
  display: flex;
  flex-direction: row;
}

.ant-descriptions-item-label::after {
  display: none;
}

.index_a10-widgets-panel-header-title__3oWps {
  float: none;
}


.ant-calendar-disabled-cell.ant-calendar-today 
.ant-calendar-date::before {
  left: 0px;
  width: 32px;
  height: 32px;
}

.auth-description {
  .ant-descriptions-row > td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .ant-descriptions-item > span {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}