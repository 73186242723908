
.status-icon {
    & {
        position: relative;
        display: inline-block;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        vertical-align: middle;
    }
    &-16 {
        width: 16px !important;
        height: 16px !important;
        .inner-text {
            font-size: 12px;
            line-height: 12px;
            transform: translate(-50%, -50%) scale(0.833);
        }
    }
    &-32 {
        width: 32px !important;
        height: 32px !important;
        .inner-text {
            font-size: 12px;
            line-height: 16px;
        }
    }
    &.in-normal-progress {
        background-image: url(../../../../assets/images/svg/hc-icons/in-normal-progress.svg);
    }
    &.in-abnormal-progress {
        background-image: url(../../../../assets/images/svg/hc-icons/in-abnormal-progress.svg);
    }
    .inner-text {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 20px;
        transform: translate(-50%, -50%);
        color: #ffffff;
        text-align: center;
    }
}
