.ScheduleReportForm {
  div[class^='a10-widgets-panel-header-title--'] {
    width: 100%;
  }
  .s-flex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.report_expiry_msg {
  margin-bottom: 10px;
  margin-top: 5px;
}

.time-container {
  margin-top: 10px;
}
